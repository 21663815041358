import { MutableRefObject } from "react";
import { Core, WebViewerInstance } from "@pdftron/webviewer";
import { NoticeType } from "antd/es/message/interface";

import { documentsFetcher, redactionsFetcher } from "../../services/api";

import { downloadFileHidden, generateRandomId } from "../common";

import { Maybe } from "../../types/common";
import { Document } from "../../types/documents";
import { Mask, MaskType, Quad } from "../../types/masks";
import { CustomAnnotation, RedactionInfo } from "../../types/redaction";
import { Results } from "../../types/subtask";

import {
  addInitialCustomDataRedaction,
  createRedactionQuads,
  adaptFontSizeToRedaction,
  addInitialAnnotations,
  createInitialRedactions,
  updateRedactionToSend,
  refreshRedactionAnnotationFromMask,
} from "./redactions";

type HeaderItem = {
  dataElement: string;
};

const downloadDocument = async (
  taskId: number,
  subtaskId: string,
  intervalId: NodeJS.Timer,
  messageId: string,
  sendMessage: (
    message: string,
    messageType: NoticeType,
    duration?: number,
    key?: string
  ) => void
) => {
  const { subtask, results } = await documentsFetcher.getDownloadDocuments(
    taskId,
    subtaskId
  );

  if (subtask.status === "SUCCESS") {
    const { url, filename } = results as Results;
    await downloadFileHidden(url, filename);
    sendMessage(
      "The clinical document with fixed masks already downloaded.",
      "success",
      1.5,
      messageId
    );
    clearInterval(intervalId);
  } else if (subtask.status === "FAILED") {
    clearInterval(intervalId);
    throw Error("Subtask status failed");
  }
};

export const handleCustomHeader = (
  instance: WebViewerInstance,
  docSelectedRef: MutableRefObject<Maybe<Document>>,
  taskId: number,
  sendMessage: (
    message: string,
    messageType: NoticeType,
    duration?: number,
    key?: string
  ) => void
) => {
  instance.UI.setHeaderItems((header) => {
    const headerItems = header
      .getItems()
      .filter(
        (headerItem) =>
          (headerItem as HeaderItem).dataElement === "searchButton" ||
          (headerItem as HeaderItem).dataElement === "zoomOverlayButton"
      );
    const searchButton = headerItems.find(
      (headerItem) => (headerItem as HeaderItem).dataElement === "searchButton"
    );
    const zoomOverlayButton = headerItems.find(
      (headerItem) =>
        (headerItem as HeaderItem).dataElement === "zoomOverlayButton"
    );

    const spacer = {
      type: "spacer",
    };
    const divider = {
      type: "divider",
    };
    const downloadButton = {
      type: "actionButton",
      img: "icon-download",
      onClick: async () => {
        let intervalId: NodeJS.Timer | undefined;
        try {
          const messageId = generateRandomId();
          sendMessage(
            "The clinical document with fixed masks is being prepared for download. Please, wait a moment.",
            "loading",
            0,
            messageId
          );
          const { subtask_id } = await documentsFetcher.getFlattenedDocument(
            taskId,
            docSelectedRef.current?.id as string
          );

          intervalId = setInterval(
            () =>
              downloadDocument(
                taskId,
                subtask_id,
                intervalId as NodeJS.Timer,
                messageId,
                sendMessage
              ),
            10000
          );
        } catch (error) {
          sendMessage(
            "Something was wrong downloading file, please try in a few moments",
            "error"
          );
          clearInterval(intervalId);
        }
      },
      dataElement: "downloadDocument",
      title: "Download file with applied fixed masks.",
    };
    header.update([
      spacer,
      searchButton as object,
      divider,
      zoomOverlayButton as object,
      divider,
      downloadButton,
      spacer,
    ]);
  });
};

export const handleDocumentLoaded = (
  masks: Mask[],
  instance: WebViewerInstance
) => {
  const redactions = createInitialRedactions(masks, instance);

  if (redactions.length === 0) return;

  redactions.forEach((redaction) =>
    addInitialAnnotations(instance, redaction as CustomAnnotation)
  );
};

export const handleAnnotationChanged = (
  instance: WebViewerInstance,
  sendMessage: (
    message: string,
    messageType: NoticeType,
    duration?: number
  ) => void,
  onResetMaskForm: () => void,
  redactionInfo: MutableRefObject<RedactionInfo>,
  maskSelectedRef: MutableRefObject<MaskType>,
  freeTextRef: MutableRefObject<string>,
  refreshMasks: (taskId: number, documentId: string) => Promise<void>,
  refreshNotifications: () => void
) => {
  const { Core } = instance;
  const { annotationManager } = Core;

  annotationManager.addEventListener(
    "annotationChanged",
    (redactions: Core.Annotations.RedactionAnnotation[], action, info) => {
      if (info.imported) return;
      if (info.source === "redactionApplied") return; // Esto se sucede cuando se aplican las mascaras
      const redaction = redactions[0];
      if (action === "add") {
        handleAddRedaction(
          redaction as CustomAnnotation,
          instance,
          redactionInfo,
          maskSelectedRef,
          freeTextRef,
          sendMessage,
          refreshMasks,
          refreshNotifications
        );
      }
      if (
        action === "delete" &&
        info.source !== "refresh" &&
        info.source !== "replicate"
      ) {
        handleDeleteRedaction(
          redaction,
          instance,
          redactionInfo,
          sendMessage,
          refreshMasks,
          refreshNotifications
        );
      }
      if (action === "modify" && info.source === "resize") {
        handleLockResizeRedaction(instance, redactions[0]);
        sendMessage(
          "Masks can't be edited. To make changes, delete and create a new one.",
          "warning",
          2.5
        );
      }
      const {
        Core: { Tools, documentViewer },
      } = instance;

      const initialTool = new Tools.AnnotationEditTool(documentViewer);
      documentViewer.setToolMode(initialTool);
      onResetMaskForm();
    }
  );
};

const handleAddRedaction = (
  redaction: CustomAnnotation,
  instance: WebViewerInstance,
  redactionInfo: MutableRefObject<RedactionInfo>,
  maskSelectedRef: MutableRefObject<MaskType>,
  freeTextRef: MutableRefObject<string>,
  sendMessage: (
    message: string,
    messageType: NoticeType,
    duration?: number
  ) => void,
  refreshMasks: (taskId: number, documentId: string) => Promise<void>,
  refreshNotifications: () => void
) => {
  const isCreatedFromError = redaction.getCustomData("deleted") === "error";
  if (isCreatedFromError)
    return instance.Core.annotationManager.redrawAnnotation(redaction);

  if (redaction.getCustomData("added")) return;

  updateRedactionToSend(
    redactionInfo.current,
    redaction,
    maskSelectedRef.current,
    freeTextRef.current
  );

  redaction.setCustomData("added", "true");

  sendMessage("Creating mask...", "loading", 0);
  redactionsFetcher
    .createMask(redactionInfo.current)
    .then((maskCreated) => {
      adaptFontSizeToRedaction(instance, redaction);
      addInitialCustomDataRedaction(
        redaction,
        maskCreated.id,
        maskCreated.occurrences[0],
        maskCreated.occurrences[0].quads
      );
      if (maskCreated.occurrences.length) {
        refreshRedactionAnnotationFromMask(maskCreated, instance);
      }
      sendMessage("The mask was successfully created", "success");
      refreshMasks(
        redactionInfo.current.taskId,
        redactionInfo.current.documentId
      );
      refreshNotifications();
    })
    .catch((e) => {
      redaction.setCustomData("created", "error");
      instance.Core.annotationManager.deleteAnnotation(redaction);
      sendMessage(
        `Mask couldn’t be created. Please, retry in a few moments. ${e.message}`,
        "error"
      );
    });
};

const handleDeleteRedaction = (
  redaction: Core.Annotations.RedactionAnnotation,
  instance: WebViewerInstance,
  redactionInfo: MutableRefObject<RedactionInfo>,
  sendMessage: (
    message: string,
    messageType: NoticeType,
    duration?: number
  ) => void,
  refreshMasks: (taskId: number, documentId: string) => Promise<void>,
  refreshNotifications: () => void
) => {
  const isDeletedFromError = redaction.getCustomData("created") === "error";
  if (isDeletedFromError) return;
  if (redaction.getCustomData("deleted") === "true") return;
  if (redaction.getCustomData("massive_delete") === "true") return;
  redaction.setCustomData("deleted", "true");

  const { taskId, documentId } = redactionInfo.current;
  const maskId = parseInt(redaction.getCustomData("mask_id"));
  const occurrenceId = parseInt(redaction.getCustomData("occurrence_id"));
  const occurrenceRedactions = instance.Core.annotationManager
    .getAnnotationsList()
    .filter(
      (annot) =>
        annot instanceof instance.Core.Annotations.RedactionAnnotation &&
        annot.getCustomData("occurrence_id") === occurrenceId.toString()
    );
  sendMessage("Deleting mask...", "loading", 0);
  redactionsFetcher
    .deleteOccurrence(taskId, documentId, maskId, occurrenceId)
    .then(() => {
      refreshMasks(taskId, documentId);
      refreshNotifications();
      occurrenceRedactions.forEach((r) => {
        r.setCustomData("deleted", "true");
        instance.Core.annotationManager.deleteAnnotation(r, {
          source: "replicate",
        });
      });
      sendMessage("Mask was successfully deleted", "success");
    })
    .catch((e) => {
      occurrenceRedactions.forEach((r) => {
        r.setCustomData("deleted", "error");
        instance.Core.annotationManager.addAnnotation(r, {
          source: "replicate",
        });
      });
      sendMessage(
        `Mask couldn’t be deleted. Please, retry in a few moments. ${e.message}`,
        "error"
      );
    });
};

const handleLockResizeRedaction = (
  instance: WebViewerInstance,
  redaction: Core.Annotations.RedactionAnnotation
) => {
  const { annotationManager } = instance.Core;

  const initialQuads: Quad[] = JSON.parse(
    redaction.getCustomData("initial_quads")
  );

  const quads = createRedactionQuads(initialQuads, instance);
  redaction.Quads = quads;
  annotationManager.redrawAnnotation(redaction);
};
