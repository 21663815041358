import logoOmnios from "../../assets/images/omnios-logo.png";

import LoginForm from "./LoginForm";

import styles from "./Login.module.css";

const Login = () => {
  return (
    <div className={styles.container}>
      <div className={styles.bgContent}></div>
      <div className={styles.formContent}>
        <LoginForm />
        <footer className={styles.footer}>
          <a href="https://omnios.ai/" target="blank">
            <img src={logoOmnios} alt="Omnios Logo" />
          </a>
        </footer>
      </div>
    </div>
  );
};
export default Login;
